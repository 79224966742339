@font-face {
  font-family: 'PetrovSans';
  src: local('PetrovSans'), url(./fonts/PetrovSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PetrovSans';
  font-weight: 300;
  src: local('PetrovSans'), url(./fonts/PetrovSans-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'PetrovSans';
  font-weight: 800;
  src: local('PetrovSans'), url(./fonts/PetrovSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'PetrovSans';
  font-weight: 900;
  src: local('PetrovSans'), url(./fonts/PetrovSans-Bold.ttf) format('truetype');
}

/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */

body {
  margin: 0;
  font-family: 'PetrovSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: 'PetrovSans';
  font-weight: 'inherit';
  font-size: 'inherit';
}

.about-us h1,
.about-us h2 {
  color: #8C52FF;
}

.rounded12 {
  border-radius: 12px;
}

.rounded16 {
  border-radius: 16px;
}

.app-button:hover {
  cursor: pointer;
}

.app-button:active {
  background: #a070ff !important;
}

.carousel-container {
  width: 1000px;
}

.carousel-item {
  width: 350px;
}

.react-multi-carousel-list {
  position: unset !important;
}

@media screen and (max-width: 1024px) {
  .carousel-container {
    width: 370px;
  }

  .carousel-item {
    width: 370px;
  }

}